/*eslint-disable*/ module.exports = {
  messages: {
    '* Vyberte vše, co platí.': 'Vyberte všetko, čo platí',
    '* potvrďte prosím souhlas': '* potvrďte prosím súhlas',
    '<0>Ucelený návod</0>, jak projekt nebo startup postavit krok za krokem a <1>jak nedělat zbytečné chyby</1>.':
      '<0>Ucelený návod</0>, ako projekt alebo startup postaviť krok za krokom a <1>ako nerobiť zbytočné chyby</1>.',
    '<0>Ucelený návod</0>, jak projekt nebo startup postavit krok za krokem a<1>jak nedělat zbytečné chyby</1>.':
      '<0>Ucelený návod</0>, jak projekt nebo startup postavit krok za krokem a<1>jak nedělat zbytečné chyby</1>.',
    '<0>Videa úspěšných podnikatelů</0> k jednotlivým krokům s reálnými zkušenosti a radami.':
      '<0>Videá úspešných podnikateľov</0> k jednotlivým krokom s reálnymi skúsenosťami a radami.',
    'Ale nezapomeň, tohle stále jen začátek. Moc ti držíme palce v dalším budování tvého nápadu. Dej nám vědět, jak ti to jde na hello@startupbox.cz.':
      'Ale nezabudni, toto je stále len začiatok. Veľmi ti držíme palce v ďalšom budovaní tvojho nápadu. Daj nám vedieť, ako ti to ide.',
    'Archived projects': 'Archived projects',
    'Celé jméno': 'Celé meno',
    'Chybně zadané heslo nebo email': 'Chybne zadané heslo alebo email',
    'Co je StartupBox?': 'Čo je StartupBox?',
    'Co nejlépe popisuje tvou aktuální situaci?':
      'Čo najlepšie popisuje tvoju aktuálnu situáciu?',
    'Co nás nejvíc těší': 'Čo nás najviac teší',
    'Co o nás říkají startupisté?': 'Čo o nás hovoria startupisti?',
    'Co získáš': 'Čo získaš',
    'Další kapitola': 'Ďalšia kapitola',
    Dashboard: 'Prehľad',
    'Deník projektu': 'Denník nápadu',
    Dokončeno: 'Dokončené',
    Dokončit: 'Dokončiť',
    'Doporučeno přímo tobě': 'Odporúčané priamo tebe',
    Doporučujeme: 'Odporúčame',
    'Feedback:': 'Feedback:',
    Finance: 'Financie',
    'Fáze projektu': 'Fáza nápadu',
    'Garant sekce': 'Garant sekcie',
    Grafika: 'Grafika',
    'Gratulujeme!': 'Gratulujeme!',
    HR: 'HR',
    Heslo: 'Heslo',
    'Heslo bylo změněno': 'Heslo bolo zmenené',
    'Heslo musí mít alespoň 6 znaků.': 'Heslo musí mať aspoň 6 znakov.',
    Investice: 'Investície',
    'Jak budeš Deníkem procházet, budeme ti ukazovat, kde se nacházíš a kolik ti ještě zbývá.':
      'Ako budeš Denníkom prechádzať, budeme ti ukazovať, kde sa nachádzaš a koľko ti ešte zostáva.',
    'Jak jsi daleko': 'Ako si ďaleko',
    'Jak jsi na tom se zákazníky?': 'Ako si na tom so zákazníkmi?',
    'Jak to vlastně funguje a co zde najdete?':
      'Ako to vlastne funguje a čo tu nájdete?',
    'Jakmile budeš s kapitolou hotov, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.':
      'Akonáhle budeš s kapitolou hotový, označ si ju ako dokončenú. Uvidíš tak pekne svoj posun dopredu.',
    'Je super, že jsi se do toho pustil. Teď nám prosím řekni něco málo o sobě. Pamatuj, čím upřímnější a specifičtější budeš, tím větší máš šanci na napojení na ty správné lidi a investory, kteří ti pomůžou s rozjezdem. Neboj, nezabere to více, než pár minut.':
      'Je super, že si sa do toho pustil. Teraz nám prosím povedz niečo málo o sebe. Pamätaj, čím úprimnejší a špecifickejší budeš, tým väčšiu máš šancu na napojenie na tých správnych ľudí a investorov, ktorí ti pomôžu s rozjazdom. Neboj, nezaberie to viac, ako pár minút.',
    'Jestliže chcete podnikat, nedělejte spoustu chyb, které dělat nemusíte. Pojďte na StartupBox, který vás naučí, co dělat správně a jak to dělat, abyste byli úspěšní.':
      'Jestliže chcete podnikat, nedělejte spoustu chyb, které dělat nemusíte. Pojďte na StartupBox, který vás naučí, co dělat správně a jak to dělat, abyste byli úspěšní.',
    'Ještě nemáte účet?': 'Ešte nemáte účet?',
    Jiný: 'Iný',
    Jméno: 'Meno',
    'Jméno projektu': 'Meno nápadu',
    'Jupí, tvůj Deník je kompletně hotový! Skvělá práce! Celý tým StartupBoxu ti od srdce gratuluje!':
      'Jupí, tvoj Denník je kompletne hotový! Skvelá práca! Celý tím ti od srdca gratuluje!',
    'KOMPLETNÍ OBSAH KAPITOLY JE DOSTUPNÝ JEN PRO REGISTROVANÉ UŽIVATELE STARTUPBOX':
      'KOMPLETNÝ OBSAH KAPITOLY JE DOSTUPNÝ LEN PRE REGISTROVANÝCH POUŽÍVATEĽOV STARTUPBOX',
    Kapitola: 'Kapitola',
    'Každá kapitola se skládá ze tří částí: video s podnikateli, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění udělat.':
      'Každá kapitola sa skladá z troch častí: video s podnikateľmi, následne štipka teórie a potom konkrétne nástroje a kroky, čo pre splnenie urobiť.',
    Komunita: 'Komunita',
    'Kód pro obnovení z emailu': 'Kód pre obnovenie z emailu',
    'MVP již mám a chci ho vylepšit': 'MVP už mám a chcem ho vylepšiť',
    Management: 'Management',
    Marketing: 'Marketing',
    'Moje Odznaky': 'Moje Odznaky',
    'Moje Poznámky': 'Moje Poznámky',
    'Moje projekty': 'Moje projekty',
    'Možnost zúčastnit se pravidelných <0>Validation Challenges</0>, kde si ověříš svůj nápad a dostaneš zpětnou vazbu od mentorů i investorů.':
      'Možnosť zúčastniť sa pravidelných <0>Validation Challenges</0>, kde si overíš svoj nápad a dostaneš spätnú väzbu od mentorov aj investorov.',
    'Mám hotový produkt, ale zákazníci o něj nemají zájem':
      'Mám hotový produkt, ale zákazníci o neho nemajú záujem',
    'Mám nápad a chci na něm zapracovat': 'Mám nápad a chcem na ňom zapracovať',
    'Mám nápad, o kterém teď přemýšlím': 'Mám nápad o ktorom teraz rozmýšľam ',
    'Mám platící zákazníky': 'Mám platiacich zákazníkov',
    'Mám produkt, platící zákazníky a teď chci se svým startupem vyrůst':
      'Mám produkt, platiacich zákazníkov a teraz chcem so svojim startupom rásť',
    'Mám prototyp produktu (vizualizace, modely atp.)':
      'Mám prototyp produktu (vizualizácia, modely, atď.)',
    'Mám prototyp, který je potřeba vyzkoušet':
      'Mám prototyp, ktorý treba vyskúšať',
    'Mám první verzi produktu (MVP), kterou teď vylepšuji':
      'Mám prvú verziu produktu (MVP), ktorú teraz vylepšujem',
    Méně: 'Menej',
    'Můj profil': 'Môj profil',
    Nahrávání: 'Nahrávanie',
    'Najít investora': 'Nájsť investora',
    'Najít mentora': 'Nájsť mentora',
    'Najít nápad, na kterém budu pracovat':
      'Nájsť nápad, na ktorom budem pracovať ',
    'Najít spoluzakladatele': 'Nájsť spoluzakladateľov ',
    'Nastavit nové heslo': 'Nastaviť nové heslo',
    Networking: 'Networking',
    'Nové Heslo': 'Nové Heslo',
    'Nové heslo *': 'Nové heslo *',
    'Nový projekt': 'Nový nápad',
    'Nyní se můžete přihlásit s novým heslem':
      'Teraz sa môžete prihlásiť s novým heslom',
    'Název projektu': 'Názov nápadu',
    'Něco se pokazilo': 'Niečo sa pokazilo',
    'Něco se pokazilo, zkuste prosím stránku obnovit':
      'Niečo sa pokazilo, skúste prosím stránku obnoviť',
    'Něco se pokazilo, zkuste to prosím znovu':
      'Niečo sa pokazilo, skúste to prosím znova',
    Obchod: 'Obchod',
    'Obnovení hesla': 'Obnovenie hesla',
    'Obnovit heslo': 'Obnoviť heslo',
    Odborníci: 'Odborníci',
    Odeslat: 'Odoslať',
    Odesláno: 'Odoslané',
    'Odesílání...': 'Odosielanie…',
    'Odhlásit se': 'Odhlásiť sa',
    'Opravdu chcete projekt {projectName} smazat?': [
      'Naozaj chcete nápad ',
      ['projectName'],
      ' zmazať?',
    ],
    'Opravdu chcete smazat svůj profil?': 'Naozaj chcete zmazať svoj profil?',
    'Opravdu chci projekt smazat': 'Naozaj chcem nápad zmazať',
    'Opravdu chci svůj profil smazat': 'Naozaj chcem svoj profil zmazať',
    'Ověření dokončeno': 'Overenie dokončené',
    'Podle toho, v jaké jsi fázi, ti zvýrazňujeme některé kapitoly. I když už jsi dál, doporučujeme nepřeskakovat předchozí fáze. Mohl/a jsi něco velmi důležitého přeskočit.':
      'Podľa toho, v akej si fáze, ti zvýrazňujeme niektoré kapitoly. Aj keď už si ďalej, odporúčame nepreskakovať predchádzajúce fázy. Mohol/a si niečo veľmi dôležité preskočiť.',
    Pokračovat: 'Pokračovať',
    Pokračování: 'Pokračovanie',
    'Pokud chceš, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu se může hodit pro potenciální investory i spoluzakladatele.':
      'Ak chceš, píš si poznámky k jednotlivým témam. Porovnanie myšlienok i možnosť exportu sa môže hodiť pre potenciálnych investorov aj spoluzakladateľov.',
    'Pokud už účet máte, stačí se': 'Pokiaľ už účet máte, stačí sa',
    'Poslední update': 'Posledné úpravy',
    Použít: 'Použiť',
    Poznámky: 'Poznámky',
    'Pravidelně získávám zpětnou vazbu (ať na hotový či připravovaný produkt)':
      'Pravidelne získavam spätnú väzbu (či na hotový alebo pripravovaný produkt)',
    'Pro každý krok ti doporučíme <0>nástroje, experty a podporovatele</0>, kteří ti pomohou.':
      'Na každý krok ti odporučíme <0>nástroje, expertov a podporovateľov</0>, ktorí ti pomôžu.',
    'Produkt mám, ale zatím žádný zákazník':
      'Produkt mám, ale zatiaľ žiadny zákazník',
    'Produktový management': 'Produktový management',
    'Profil partnera': 'Profil partnera',
    'Profilová fotka': 'Profilová fotka',
    'Programování (Backend)': 'Programovanie (Backend)',
    'Programování (Frontend)': 'Programovanie (Frontend)',
    'Projekt byl smazán': 'Nápad bol zmazaný',
    'Prosím vyplň základní informace o projektu. Díky nim dokážeme personalizovat doporučení a napojit na relevantní partnery.':
      'Prosím vyplň základné informácie o projekte. Vďaka nim dokážeme personalizovať odporúčania a napojiť na relevantných partnerov.',
    'Prošla jsem několik akcelerátorů a deník, který je ve StartupBoxu, by měl být základem každého inkubátoru a akcelerátoru.':
      'Prošla jsem několik akcelerátorů a deník, který je ve StartupBoxu, by měl být základem každého inkubátoru a akcelerátoru.',
    'První krok': 'Prvý krok',
    'První krok jste udělali pomocí StartupBoxu a získali jste první odznak. Pokračujte v této cestě a sbírejte další odznaky.':
      'Prvý krok ste urobili a získali ste prvý odznak. Pokračujte v tejto ceste a zbierajte ďalšie odznaky.',
    Právo: 'Právo',
    'Průvodce má čtyři fáze, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.':
      'Sprievodca má štyri fázy, ktorými ťa sprevádza. Od vymýšľania nápadov až po rozjazd firmy.',
    'Předchozí kapitola': 'Predchádzajúca kapitola',
    'Přejít na Dashboard': 'Prejsť na Prehľad',
    'Přejít na Dashboard po smazání': 'Prejsť na Prehľad po zmazaní',
    Přidat: 'Pridať',
    'Přidat projekt': 'Pridať nápad',
    Přihlašování: 'Prihlasovanie',
    'Přihlásit se': 'Prihlásiť sa',
    Příjmení: 'Priezvisko',
    Reference: 'Referencie',
    Registrace: 'Registrácia',
    'Registrace s tímto emailem se nezdařila':
      'Registrácia s týmto emailom zlyhala',
    'Registrací souhlasím s <0>podmínkami použití</0> a <1>zásadami ochrany osobních údajů</1>.':
      'Registráciou súhlasím s <0>podmienkami použitia</0> a <1>zásadami ochrany osobných údajov</1>.',
    'Registrovat se': 'Registrovať sa',
    'Rozjeď svůj STARTUP i bez nápadu | online aplikace Startupbox':
      'Rozbehni svoj STARTUP aj bez nápadu | online aplikácia Startupbox',
    'Sleduj tvůj pokrok': 'Sleduj tvoj pokrok',
    'Smazat profil': 'Zmazať profil',
    'Smazat projekt': 'Zmazať nápad',
    'Speciální nabídka': 'Špeciálna ponuka',
    'Speciální nabídky pro startupy': 'Špeciálne ponuky pre startupy',
    'Staneš se <0>součástí komunity startupistů</0>, kteří řeší ty samé věci jako ty a dodají ti odvahu a motivaci.':
      'Staneš sa <0>súčasťou komunity startupistov</0>, ktorí riešia tie isté veci a dodajú ti odvahu a motiváciu.',
    'StartupBox nám pomohl s jejich deníkem startupisty, který nás nasměroval a ucelil naše myšlenky a podklady, co jsme měli a máme.':
      'StartupBox nám pomohl s jejich deníkem startupisty, který nás nasměroval a ucelil naše myšlenky a podklady, co jsme měli a máme.',
    'Stránka neexistuje': 'Stránka neexistuje',
    'Tato akce je nevratná a příjdete tak o všechny uložené poznámky. Pokud chcete projekt skutečně smazat, zadejde prosím níže název projektu':
      'Táto akcia je nevratná a prídete tak o všetky uložené poznámky. Pokiaľ chcete nápad skutočne zmazať, zájde prosím nižšie názov nápadu',
    'Tato akce je nevratná a příjdete tak o všechny vaše projekty a uložné poznámky. Pokud chcete Váš účet skutečně smazat, zadejde prosím níže celé Vaše jméno (Křestní jméno a příjmení)':
      'Táto akcia je nevratná a prídete tak o všetky vaše nápady a úložné poznámky. Pokiaľ chcete Váš účet skutočne zmazať, zájde prosím nižšie celé Vaše meno (Krstné meno a priezvisko)',
    'Tvoje schopnosti': 'Tvoje schopnosti',
    'UX/UI Design': 'UX/UI Design',
    'Ukládání změn...': 'Ukladanie zmien…',
    'Uložit změny': 'Uložiť zmeny',
    'Upravit projekt': 'Upraviť nápad',
    'Vyber kraj': 'Vyber kraj',
    'Vyber, co umíš a v jakých oblastech se cítíš silný. Čím upřímnější budeš, tím lépe využiješ StartupBox.':
      'Vyber, čo vieš av akých oblastiach sa cítiš silný. Čím úprimnejší budeš, tým lepšie aplikáciu využiješ.',
    'Vyberte prosím alespoň jednu možnost':
      'Vyberte prosím aspoň jednu možnosť',
    'Vytvořit jiný projekt': 'Vytvoriť iný nápad',
    'Vytvořit profil': 'Vytvoriť profil',
    'Vytvořit projekt': 'Vytvoriť nápad',
    'Vytvořit účet': 'Vytvoriť účet',
    'Vytváření profilu...': 'Vytváranie profilu…',
    'Vytváření projektu...': 'Vytváranie nápadu…',
    'Vyzkoušej ZDARMA online aplikaci Startupbox a rozjeď svůj projekt. Poradíme, co je důležité i BEZ nápadu a jak ho následně najít. Napojíme tě na investory.':
      'Vyskúšaj ZADARMO online aplikáciu a rozbehni svoj projekt. Poradíme, čo je dôležité aj BEZ nápadu a ako ho následne nájsť. Napojíme ťa na investorov.',
    'Váš email': 'Váš email',
    'Váš email byl úspěšně ověřen': 'Váš email bol úspešne overený',
    Více: 'Viac',
    'Vítej ve StartupBoxu!': 'Vitaj!',
    'Vítej ve startupbox': 'Vitaj',
    'Výhody a slevy': 'Výhody a zľavy',
    'Výhody, za které nemusíš platit': 'Výhody, za které nemusíš platit',
    Výsledky: 'Výsledky',
    'Za zákazníkem půjdu hned jak bude můj produkt hotový':
      'Za zákazníkom pôjdem hneď ako bude môj produkt hotový',
    'Zadejte prosím emailovou adresu se kterou jste se registrovali a zašleme vám link pro obnovení hesla':
      'Zadajte prosím emailovú adresu, s ktorou ste sa registrovali a zašleme vám link pre obnovenie hesla',
    'Zadejte prosím heslo znovu*': 'Zadajte prosím heslo znova *',
    'Založil jsem startup a chci z něj dostat to nejlepší':
      'Založil som startup a chcem z neho dostať to najlepšie',
    'Zapomenuté heslo': 'Zabudnuté heslo',
    'Zapomenuté heslo?': 'Zabudnuté heslo?',
    'Zaregistrujte se a získejte zdarma přístup k dalšímu obsahu':
      'Zaregistrujte sa a získajte zadarmo prístup k ďalšiemu obsahu',
    'Zatím nemám nápad, ale chci ho najít':
      'Zatiaľ nemám nápad, ale chcem ho nájsť',
    'Zatím nemáte žádné projekty. Začněte svou podnikatelskou cestu hned teď!':
      'Zatiaľ nemáte žiadne projekty. Začnite svoju podnikateľskú cestu hneď teraz!',
    Zavřít: 'Zavrieť',
    'Zjistit, proč můj produkt nechce nikdo kupovat':
      'Zistiť, prečo môj produkt nechce nikto kupovať',
    'Zkontroluj prosím tvoji e-mailovou schránku, poslali jsme ti potvrzovací e-mail, abychom měli jistotu, že je to tvůj e-mail. Stačí kliknout na odkaz v e-mailu.':
      'Skontroluj prosím tvoju e-mailovú schránku, poslali sme ti potvrdzovací e-mail, aby sme mali istotu, že je to tvoj e-mail. Stačí kliknúť na odkaz v e-maile.',
    Změnit: 'Zmeniť',
    'Změny byli uloženy': 'Zmeny boli uložené',
    'Změny uloženy': 'Zmeny uložené',
    'Zpět na přihlášení': 'Späť na prihlásenie',
    'Zákaznická podpora': 'Zákaznícka podpora',
    'Získat informace o tom, jak úspěšně podnikat':
      'Získať informácie o tom, ako úspešne podnikať',
    'Získat konzultaci zdarma': 'Získať konzultáciu zdarma',
    benefit1:
      'Ucelený návod, ako overiť zmysluplnosť nápadu, vrátane konkrétneho obsahu',
    benefit2: 'Pre každú úlohu budeš mať k dispozícii praktické nástroje',
    benefit3:
      'Odborníky a mentory budeš mať na dosah svojej ruky, osloviť ich môžeš rovno z aplikácie',
    'hesla nejsou stejná': 'heslá nie sú rovnaké',
    'heslo musí mít minimálně 6 znaků': 'heslo musí mať minimálne 6 znakov',
    kraj: 'kraj',
    'mažeme to': 'mažeme to',
    menu: 'menu',
    'můj účet': 'môj účet',
    'nastavování...': 'nastavovanie…',
    nebo: 'alebo',
    obor: 'odbor',
    'potřeba zadat': 'potreba zadať',
    'přejít na přihlášení': 'prejsť na prihlásenie',
    přihlásit: 'prihlásiť',
    'zadejte prosím email v platném formátu':
      'zadajte prosím e-mail v platnom formáte',
    zavřít: 'zavrieť',
    'zobrazit vše': 'zobraziť všetko',
    zpět: 'späť',
    'zpět k úkolu': 'späť k úlohe',
    '{buttonLabel}': [['buttonLabel']],
    '{label}': [['label']],
    'Úprava profilu': 'Úprava profilu',
    'Čeho chceš pomocí StartupBoxu docílit?':
      'Čo chceš pomocou aplikácie docieliť?',
    'Čeho se ještě snažíte dosáhnout?': 'Čo sa ešte snažíte dosiahnuť?',
  },
};
