export const colors = {
  brand: '#4273A3',
  brandLight: 'rgb(83,138,208)',

  pink: '#E4A999',
  secondary: '#FAF5F4',

  beige: '#F6ECEB',
  lightBeige: '#FAF5F4',
  lightBeige2: '#F6ECEB',

  blackMain: '#1F1F1F',

  secondaryLight: '#ffa200c9',
  lightBlue: '#e6ecf4',
  cardLightBlue: '#E7F0FC',
  green: '#4EB57B',
  greenLight: '#65cd91',
  greenBanner: '#dbf0e4',
  white: '#FFFFFF',
  lightGrey: '#F8F8F8',
  darkGrey: '#666666',
  black: '#141125',
  error: '#E73024',
  errorLight: '#E73024',
};
