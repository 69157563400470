import { PhaseStage, UserRole } from '../../../__generated__/globalTypes';
import { EnumLocalStorage } from '../enums/generalEnums';

export const isAdmin = (user) =>
  user?.role === UserRole.ADMIN || user?.role === UserRole.SUPER_ADMIN;

export const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const setLastVisitedPhase = (phase: PhaseStage) =>
  window.localStorage.setItem(EnumLocalStorage.LastVisitedDiaryStage, phase);

export const getLastVisitedPhase = (): string =>
  window.localStorage.getItem(EnumLocalStorage.LastVisitedDiaryStage);

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;
