export enum EnumLocalStorage {
  LastVisitedDiaryStage = 'lastVisitedDiaryStage',
}

export enum EnumAuthCookies {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  VerificationToken = 'verificationToken',
  RequestId = 'requestId',
}

export enum EnumProjectPhase {}
