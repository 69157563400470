/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthProvider {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  LINKEDIN = 'LINKEDIN',
}

export enum EmailVerification {
  DONE = 'DONE',
  NOT_NEEDED = 'NOT_NEEDED',
  PENDING = 'PENDING',
}

export enum PhaseStage {
  BUSINESS = 'BUSINESS',
  IDEA = 'IDEA',
  IDEATION = 'IDEATION',
  MVP = 'MVP',
  PROTOTYPE = 'PROTOTYPE',
}

export enum ProjectPhase {
  FINISHED_PRODUCT = 'FINISHED_PRODUCT',
  IDEALIZATION = 'IDEALIZATION',
  INVESTMENT_READY = 'INVESTMENT_READY',
  PROTOTYPING = 'PROTOTYPING',
}

export enum QuestionTypeEnum {
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export enum QuizTypeEnum {
  CHAPTER_ENTRY = 'CHAPTER_ENTRY',
  CHAPTER_EXIT = 'CHAPTER_EXIT',
  FINAL = 'FINAL',
}

export enum UserCountry {
  CZECHIA = 'CZECHIA',
  SLOVAKIA = 'SLOVAKIA',
}

export enum UserGender {
  MAN = 'MAN',
  NOT_ANSWERED = 'NOT_ANSWERED',
  WOMAN = 'WOMAN',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export interface CreateQuestionInput {
  cmsQuestionId: string;
  type: QuestionTypeEnum;
  selectedAnswer: CreateSelectedAnswerInput;
}

export interface CreateQuizInput {
  cmsQuizId: string;
  quizType: QuizTypeEnum;
  chapterId?: string | null;
  questions: CreateQuestionInput[];
}

export interface CreateSelectedAnswerInput {
  cmsAnswerId?: string | null;
  value: string;
  isCorrectAnswer?: boolean | null;
}

export interface OnboardingInputs {
  question: string;
  answer: string[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
