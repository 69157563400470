/*eslint-disable*/ module.exports = {
  messages: {
    '* Vyberte vše, co platí.': '* Select all applicable ',
    '* potvrďte prosím souhlas': '* please confirm that you agree',
    '<0>Ucelený návod</0>, jak projekt nebo startup postavit krok za krokem a <1>jak nedělat zbytečné chyby</1>.':
      '<0>Comprehensive step by step guide</0>on how to build a startup and avoid unnecessary mistakes.<1></1>.',
    '<0>Videa úspěšných podnikatelů</0> k jednotlivým krokům s reálnými zkušenosti a radami.':
      '<0>Videos of successful entrepreneurs</0> giving advice for individual steps based on real-life experience.',
    'Ale nezapomeň, tohle stále jen začátek. Moc ti držíme palce v dalším budování tvého nápadu. Dej nám vědět, jak ti to jde na hello@startupbox.cz.':
      'Don’t forget, this is still the beginning of your venture. We’re all keeping the fingers crossed for you and your idea. Please, give us know how is it going at hello@startupbox.cz.',
    'Archived projects': 'Archived projects',
    'Celé jméno': 'Full name',
    'Chybně zadané heslo nebo email': 'Incorrect e-mail or password',
    'Co je StartupBox?': 'What is StartupBox?',
    'Co nejlépe popisuje tvou aktuální situaci?':
      'What describes the best your current situation?',
    'Co nás nejvíc těší': 'What pleases us the most',
    'Co o nás říkají startupisté?': 'What do startupists say about us?',
    'Co získáš': 'Benefits',
    'Další kapitola': 'Next chapter',
    Dashboard: 'Dashboard',
    'Deník projektu': 'Idea Diary',
    Dokončeno: 'Done',
    Dokončit: 'Mark as Done',
    'Doporučeno přímo tobě': 'Recommended for you',
    Doporučujeme: 'Recommended',
    'Feedback:': 'Feedback:',
    Finance: 'Finance',
    'Fáze projektu': 'Phase of the idea',
    'Garant sekce': 'Chapter guarantor',
    Grafika: 'Graphic',
    'Gratulujeme!': 'Congratulations!',
    HR: 'HR',
    Heslo: 'Password',
    'Heslo bylo změněno': 'Your password has been changed',
    'Heslo musí mít alespoň 6 znaků.':
      'Password must be at least 6 characters long.',
    Investice: 'Investments',
    'Jak budeš Deníkem procházet, budeme ti ukazovat, kde se nacházíš a kolik ti ještě zbývá.':
      'During your way through the Diary content we gonna show you where you are and what your progress is.',
    'Jak jsi daleko': 'How far you are',
    'Jak jsi na tom se zákazníky?':
      'What’s your status with the customer relationship?',
    'Jak to vlastně funguje a co zde najdete?':
      'How it is working and what you can find here?',
    'Jakmile budeš s kapitolou hotov, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.':
      'When you’re finished with the chapter, mark it as Done. So you can track your progress forward.',
    'Je super, že jsi se do toho pustil. Teď nám prosím řekni něco málo o sobě. Pamatuj, čím upřímnější a specifičtější budeš, tím větší máš šanci na napojení na ty správné lidi a investory, kteří ti pomůžou s rozjezdem. Neboj, nezabere to více, než pár minut.':
      'That’s great that you’ve started! Please tell us more about you. Remember, more frank and specific you will be, more valuable answers and tips you can get.',
    'Jestliže chcete podnikat, nedělejte spoustu chyb, které dělat nemusíte. Pojďte na StartupBox, který vás naučí, co dělat správně a jak to dělat, abyste byli úspěšní.':
      "If you want to start a business, don't make unnecessary mistakes. Come to StartupBox. It will teach you what is right and what to do it to be successful.",
    'Ještě nemáte účet?': 'Don’t have an account yet?',
    Jiný: 'Other',
    Jméno: 'First name',
    'Jméno projektu': 'Idea name',
    'Jupí, tvůj Deník je kompletně hotový! Skvělá práce! Celý tým StartupBoxu ti od srdce gratuluje!':
      '🎉 Hohohooo, your Diary has been finished! Great job! Our whole team is giving you a warm congratulations',
    'KOMPLETNÍ OBSAH KAPITOLY JE DOSTUPNÝ JEN PRO REGISTROVANÉ UŽIVATELE STARTUPBOX':
      'COMPLETE CHAPTER CONTENT IS AVAILABLE ONLY TO REGISTERED STARTUPBOX USERS',
    Kapitola: 'Chapter',
    'Každá kapitola se skládá ze tří částí: video s podnikateli, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění udělat.':
      'Each chapter consist of three main parts: video tips from the entrepreneur, than the theory basics and tips for the tools and next steps.',
    Komunita: 'Community',
    'Kód pro obnovení z emailu': 'Code for the password reset',
    'MVP již mám a chci ho vylepšit':
      'Have a product and I’d love to improve it',
    Management: 'Management',
    Marketing: 'Marketing',
    'Moje Odznaky': 'My badges',
    'Moje Poznámky': 'My notes',
    'Moje projekty': 'My ideas',
    'Možnost zúčastnit se pravidelných <0>Validation Challenges</0>, kde si ověříš svůj nápad a dostaneš zpětnou vazbu od mentorů i investorů.':
      'An opportunity to participate in regular <0>Validation Challenges</0>, where you can verify your idea and get feedback from mentors and investors.',
    'Mám hotový produkt, ale zákazníci o něj nemají zájem':
      'I have a product, but the customers are not interested',
    'Mám nápad a chci na něm zapracovat':
      'Have an idea and I’d love to work on it',
    'Mám nápad, o kterém teď přemýšlím':
      "I have an idea that I'm thinking about",
    'Mám platící zákazníky': 'I have paying customers',
    'Mám produkt, platící zákazníky a teď chci se svým startupem vyrůst':
      'I have a product, paying customers and now I want to grow',
    'Mám prototyp produktu (vizualizace, modely atp.)':
      'I have a prototype (visualisation, models etc. )',
    'Mám prototyp, který je potřeba vyzkoušet':
      'Have a prototype that I want to try out',
    'Mám první verzi produktu (MVP), kterou teď vylepšuji':
      'I have an MVP, that I am improving',
    Méně: 'Méně',
    'Můj profil': 'My profile',
    Nahrávání: 'Loading',
    'Najít investora': 'Find an investor',
    'Najít mentora': 'FInd a mentor',
    'Najít nápad, na kterém budu pracovat': 'Find an idea, that I will work on',
    'Najít spoluzakladatele': 'Find a co-founder',
    'Nastavit nové heslo': 'Setup the new password',
    Networking: 'Networking',
    'Nové Heslo': 'New password',
    'Nové heslo *': 'New password *',
    'Nový projekt': 'New idea',
    'Nyní se můžete přihlásit s novým heslem':
      'Now you can log in with the new password',
    'Název projektu': 'Name of your idea',
    'Něco se pokazilo': 'Something went wrong…',
    'Něco se pokazilo, zkuste prosím stránku obnovit':
      'Something went wrong, try to reload the page',
    'Něco se pokazilo, zkuste to prosím znovu':
      'Something went wrong, try it again',
    Obchod: 'Business',
    'Obnovení hesla': 'Forgotten password',
    'Obnovit heslo': 'Password recovery',
    Odborníci: 'Experts',
    Odeslat: 'Send',
    Odesláno: 'Sent',
    'Odesílání...': 'Sending…',
    'Odhlásit se': 'Log out',
    'Opravdu chcete projekt {projectName} smazat?': [
      'Do you really want to delete ',
      ['projectName'],
      '?',
    ],
    'Opravdu chcete smazat svůj profil?':
      'Do you really want to delete your account?',
    'Opravdu chci projekt smazat': 'Yes, I really want to delete my idea',
    'Opravdu chci svůj profil smazat':
      'Yes, I really want to delete my profile',
    'Ověření dokončeno': 'Verification done',
    'Podle toho, v jaké jsi fázi, ti zvýrazňujeme některé kapitoly. I když už jsi dál, doporučujeme nepřeskakovat předchozí fáze. Mohl/a jsi něco velmi důležitého přeskočit.':
      'We’re highlighting for you selected chapters based on your current situation. And even if you’re skilled entrepreneur, sometimes it’s really good to think about the basics again. Don’t skip it.',
    Pokračovat: 'Next',
    Pokračování: 'Next',
    'Pokud chceš, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu se může hodit pro potenciální investory i spoluzakladatele.':
      'We recommend to write down your notes to particular topics. It could be really helpful for you, your partners or potential investors in the future.',
    'Pokud už účet máte, stačí se': 'Already have an account? Just',
    'Poslední update': 'Last update',
    Použít: 'Confirm',
    Poznámky: 'Notes',
    'Pravidelně získávám zpětnou vazbu (ať na hotový či připravovaný produkt)':
      'I am regularly getting feedback (for either done or prepared product)  ',
    'Pro každý krok ti doporučíme <0>nástroje, experty a podporovatele</0>, kteří ti pomohou.':
      'For each step, we recommend <0>tools and experts ready</0> to help along the way.',
    'Produkt mám, ale zatím žádný zákazník':
      'Have an product ready and I seek customers',
    'Produktový management': 'Product management',
    'Profil partnera': 'Partner profile',
    'Profilová fotka': 'Profile picture',
    'Programování (Backend)': 'Programing (Backend)',
    'Programování (Frontend)': 'Programing (Frontend)',
    'Projekt byl smazán': 'Idea Diary has been deleted',
    'Prosím vyplň základní informace o projektu. Díky nim dokážeme personalizovat doporučení a napojit na relevantní partnery.':
      'Fill in the basic information about your idea. Based on your input we can personalize the recommendations and even connect you to relevant partners, mentors or investors.',
    'Prošla jsem několik akcelerátorů a deník, který je ve StartupBoxu, by měl být základem každého inkubátoru a akcelerátoru.':
      'I have been through several accelerators and the project diary that is in StartupBox should be the basis of every incubator and accelerator.',
    'První krok': 'First step',
    'První krok jste udělali pomocí StartupBoxu a získali jste první odznak. Pokračujte v této cestě a sbírejte další odznaky.':
      'You’ve done the first step! And boom, there is your first reward. Continue with the good job and collect all the badges.',
    Právo: 'Law',
    'Průvodce má čtyři fáze, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.':
      'Your Diary guides you through several phases, from ideation to entering a market.',
    'Předchozí kapitola': 'Previous chapter',
    'Přejít na Dashboard': 'Go to Dashboard',
    'Přejít na Dashboard po smazání': 'Go to Dashboard after deleting',
    Přidat: 'Add',
    'Přidat projekt': 'Add idea',
    Přihlašování: 'Logging in..',
    'Přihlásit se': 'Login',
    Příjmení: 'Last name',
    Reference: 'Reference',
    Registrace: 'Registration',
    'Registrace s tímto emailem se nezdařila':
      'Registration with this e-mail failed.',
    'Registrací souhlasím s <0>podmínkami použití</0> a <1>zásadami ochrany osobních údajů</1>.':
      'By registering you accept with our <0>terms and conditions</0> and <1>privacy policy</1>.',
    'Registrovat se': 'Register',
    'Rozjeď svůj STARTUP i bez nápadu | online aplikace Startupbox':
      'Boost your Startup or find your idea | Online idea guide StartupBox',
    'Sleduj tvůj pokrok': 'Track your progress',
    'Smazat profil': 'Delete account',
    'Smazat projekt': 'Delete idea',
    'Speciální nabídka': 'Special offer',
    'Speciální nabídky pro startupy': 'Special offer for startups',
    'Staneš se <0>součástí komunity startupistů</0>, kteří řeší ty samé věci jako ty a dodají ti odvahu a motivaci.':
      'Posibility to be <0>part of a community of startupists</0>, who are facing the same problems and can provide with encouragement and motivation.',
    'StartupBox nám pomohl s jejich deníkem startupisty, který nás nasměroval a ucelil naše myšlenky a podklady, co jsme měli a máme.':
      'StartupBox guided us with their project diary, which helped us to sorting out the thoughts and materials we already had.',
    'Stránka neexistuje': 'Page doesn’t exist',
    'Tato akce je nevratná a příjdete tak o všechny uložené poznámky. Pokud chcete projekt skutečně smazat, zadejde prosím níže název projektu':
      'This action is definite and you will loose your track record and all your saved notes. If you really want to delete your idea, please fill in the name of your idea',
    'Tato akce je nevratná a příjdete tak o všechny vaše projekty a uložné poznámky. Pokud chcete Váš účet skutečně smazat, zadejde prosím níže celé Vaše jméno (Křestní jméno a příjmení)':
      'This action is definite and you will loose all the data and ideas. If you really want to delete your account, please fill in your full name to confirm the action (first name and last name)',
    'Tvoje schopnosti': 'Your skills',
    'UX/UI Design': 'UX/UI Design',
    'Ukládání změn...': 'Saving changes…',
    'Uložit změny': 'Save changes',
    'Upravit projekt': 'Edit idea',
    'Vyber kraj': 'Select a region',
    'Vyber, co umíš a v jakých oblastech se cítíš silný. Čím upřímnější budeš, tím lépe využiješ StartupBox.':
      'Select domains you feel strong at. More open you will be more you can get out of the StartupBox.',
    'Vyberte prosím alespoň jednu možnost': 'Choose at least one option please',
    'Vytvořit jiný projekt': 'Create new idea',
    'Vytvořit profil': 'Create profile',
    'Vytvořit projekt': 'Create new idea',
    'Vytvořit účet': 'Create account',
    'Vytváření profilu...': 'Creating profile…',
    'Vytváření projektu...': 'Creating idea…',
    'Vyzkoušej ZDARMA online aplikaci Startupbox a rozjeď svůj projekt. Poradíme, co je důležité i BEZ nápadu a jak ho následně najít. Napojíme tě na investory.':
      'Boost your idea. Register to StartupBox and get help for free with verification of your idea on the market. Reach the feedback, mentors and even investors!',
    'Váš email': 'Your email',
    'Váš email byl úspěšně ověřen': 'Your e-mail was successfully verified',
    Více: 'More',
    'Vítej ve StartupBoxu!': 'Welcome to StartupBox!',
    'Vítej ve startupbox': 'Welcome to StartupBox',
    'Výhody a slevy': 'Benefits and vouchers',
    'Výhody, za které nemusíš platit': "Perks you don't have to pay for",
    Výsledky: 'Results',
    'Za zákazníkem půjdu hned jak bude můj produkt hotový':
      'I will go to the customer, when my product is done',
    'Zadejte prosím emailovou adresu se kterou jste se registrovali a zašleme vám link pro obnovení hesla':
      'Please fill in the e-mail you’ve used during registration and we’ll send you an recovery e-mail',
    'Zadejte prosím heslo znovu*': 'Please fill in the password',
    'Založil jsem startup a chci z něj dostat to nejlepší':
      'I established a startup and I’d love to boost it',
    'Zapomenuté heslo': 'Forgotten password',
    'Zapomenuté heslo?': 'Forgot password?',
    'Zaregistrujte se a získejte zdarma přístup k dalšímu obsahu':
      'Register and get the access to more entrepreneurial content for free',
    'Zatím nemám nápad, ale chci ho najít':
      'Don’t have and idea yet, but I’d love to find it',
    'Zatím nemáte žádné projekty. Začněte svou podnikatelskou cestu hned teď!':
      'No ideas yet. You can start your journey straight away',
    Zavřít: 'Close',
    'Zjistit, proč můj produkt nechce nikdo kupovat':
      'Find out, why no one wants to buy my product',
    'Zkontroluj prosím tvoji e-mailovou schránku, poslali jsme ti potvrzovací e-mail, abychom měli jistotu, že je to tvůj e-mail. Stačí kliknout na odkaz v e-mailu.':
      'Please, confirm your e-mail address. Go to your inbox and click the link in the verification e-mail.',
    Změnit: 'Change',
    'Změny byli uloženy': 'Changes saved',
    'Změny uloženy': 'Changes saved',
    'Zpět na přihlášení': 'Back to login',
    'Zákaznická podpora': 'Customer Support',
    'Získat informace o tom, jak úspěšně podnikat':
      'Learn about how to have successful business',
    'Získat konzultaci zdarma': 'Získat konzultaci zdarma',
    benefit1:
      'Your personal guide through the idea verification process. Grab the aswer!',
    benefit2: 'Practical tips and tools for each and every stage of your idea',
    benefit3:
      'Exoerts and mentors are wishing the reach. You can write them straight away',
    'hesla nejsou stejná': 'Passwords don’t match',
    'heslo musí mít minimálně 6 znaků':
      'The password has to have at least 6 characters',
    kraj: 'Region',
    'mažeme to': 'Deleting',
    menu: 'Menu',
    'můj účet': 'my account',
    'nastavování...': 'Setting up…',
    nebo: 'Or',
    obor: 'Domain',
    'potřeba zadat': 'Mandatory',
    'přejít na přihlášení': 'Login',
    přihlásit: 'Login',
    'zadejte prosím email v platném formátu':
      'Please enter the e-mail in correct format',
    zavřít: 'Close',
    'zobrazit vše': 'Show all',
    zpět: 'Back',
    'zpět k úkolu': 'back to assignment',
    '{buttonLabel}': [['buttonLabel']],
    '{label}': [['label']],
    'Úprava profilu': 'Edit profile',
    'Čeho chceš pomocí StartupBoxu docílit?':
      'What are your main goals with StartupBox?',
    'Čeho se ještě snažíte dosáhnout?': 'Čeho se ještě snažíte dosáhnout?',
  },
};
