import { colors } from './colors';
import { createTheme } from '@mui/material/styles';

import { size } from './size';
import { Theme } from '@mui/material/styles/createTheme';

const baseTheme = createTheme({
  typography: {
    fontFamily: 'Work Sans',
  },

  palette: {
    primary: {
      main: colors.brand,
      dark: colors.brandLight,
      light: colors.brandLight,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.pink,
    },

    success: { main: colors.green },
    error: { main: colors.error },
  },

  breakpoints: {
    values: {
      xs: size.breakpoints.xs,
      sm: size.breakpoints.sm,
      md: size.breakpoints.md,
      lg: size.breakpoints.lg,
      xl: size.breakpoints.xl,
    },
  },
});

export const theme: Theme = createTheme(baseTheme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',

          // [baseTheme.breakpoints.up("sm")]: {
          //   fontSize: '16px',
          //   lineHeight: '24px',
          // },
        },

        h1: {
          fontSize: '30px',
          fontWeight: 500,
          lineHeight: '40px',
          letterSpacing: '0.025em',

          '@media (min-width:480px)': {
            fontSize: '60px',
            lineHeight: '80px',
          },
        },

        h2: {
          fontSize: '21px',
          lineHeight: '28px',
          fontWeight: 500,

          '&.MuiTypography-gutterBottom': {
            marginBottom: '30px',
          },

          '@media (min-width:480px)': {
            fontSize: '36px',
            lineHeight: '46px',

            '&.MuiTypography-gutterBottom': {
              marginBottom: '40px',
            },
          },
        },

        h3: {
          fontSize: '18px',
          // lineHeight: '24px',
          fontWeight: 500,

          '&.MuiTypography-gutterBottom': {
            marginBottom: '20px',
          },

          '@media (min-width:480px)': {
            fontSize: '24px',

            '&.MuiTypography-gutterBottom': {
              marginBottom: '30px',
            },
          },
        },

        h4: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',

          '@media (min-width:480px)': {
            fontSize: '18px',
            lineHeight: '24px',
          },
        },

        h5: {
          fontSize: '14px',

          lineHeight: '18px',
          textTransform: 'uppercase',

          '@media (min-width:480px)': {
            fontSize: '18px',
            lineHeight: '36px',
          },
        },

        body2: {
          fontSize: '14px',
          letterSpacing: 0,
          lineHeight: '20px',
        },

        caption: {
          fontSize: '14px',
          color: colors.darkGrey,
        },

        overline: {
          fontSize: '14px',
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 'normal',
          // padding: '5px 10px',
          //
          // '@media (min-width:480px)': {
          //   padding: '5px 10px',
          // },
        },

        text: {},

        sizeLarge: {
          height: '40px',

          '@media (min-width:480px)': {
            height: '60px',
          },
        },

        sizeMedium: {
          height: '40px',

          '@media (min-width:480px)': {
            height: '48px',
          },
        },

        sizeSmall: {
          height: '24px',

          '@media (min-width:480px)': {
            height: '30px',
          },
        },

        contained: {},

        containedPrimary: {
          fontWeight: 500,
          fontSize: 18,
          boxShadow: 'none',
          backgroundColor: colors.brand,
          color: 'white',
          '&:hover': {
            backgroundColor: colors.brandLight,
            boxShadow: 'none ',
          },

          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: colors.brandLight,
              boxShadow: 'none',
            },
          },
        },

        containedSuccess: {
          backgroundColor: colors.green,
          color: 'white',
          '&:hover': {
            backgroundColor: colors.greenLight,
          },
        },
        containedSecondary: {
          backgroundColor: colors.secondary,
          color: 'white',
          '&:hover': {
            backgroundColor: colors.secondaryLight,
          },
        },

        containedError: {
          backgroundColor: colors.error,
          color: 'white',
          '&:hover': {
            backgroundColor: colors.errorLight,
          },
        },

        outlined: {
          color: colors.black,
          borderColor: colors.black,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '44px',

          backgroundColor: 'white !important',

          '&.Mui-error': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: 'white !important',
          },
        },

        input: {
          padding: '12px 20px ',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          border: '1px blue !important',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          // backgroundColor: colors.lightBeige,
          padding: '1rem',
          border: '1px solid #E7F0FC',
          borderRadius: '7px',
          boxShadow: '0px 8px 10px rgba(9, 60, 143, 0.07)',

          '&.MuiPaper-elevation1': {
            boxShadow: '0px 8px 10px rgba(9, 60, 143, 0.07)',
          },

          [baseTheme.breakpoints.up('sm')]: {
            padding: '1.5rem',
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'scroll',
          display: 'grid',
          placeItems: 'center',
          height: '100vh',
          width: '100%',
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: colors.brand,
        },

        colorSecondary: {
          color: 'black',
          opacity: 0.12,
        },
        colorInfo: {
          color: colors.pink,
        },
      },
    },

    MuiBox: {
      outline: 'none',
    },
  },
});
